.certificate {
  position: relative;
}

.certificate::before {
  content: " ";
  display: block;
  position: absolute;
  /* left: 0; */
  /* top: 0; */
  width: 500px;
  height: 500px;
  opacity: 0.2;
  background-image: url("moh.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.contain {
  height: calc(100vh - 122px);
}
